





















































































































































































import { enumeracion } from "@/shared/dtos/enumeracion";
import { tarea } from "@/shared/dtos/tareas/tarea";
import departamentoModule from "@/store/modules/departamento-module";
import empresaModule from "@/store/modules/empresa-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import plantilla_tareasModule from "@/store/modules/plantilla_tareas-module";
import usuarioModule from "@/store/modules/usuario-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Prop, Vue } from "vue-property-decorator";
import invetarioModule from "@/store/modules/invetario-module";
import proyectoModule from "@/store/modules/proyecto-module";
@Component({
  components: {
    DxDateBox: () => import("devextreme-vue/date-box"),
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class TareaPlatillaFormulario extends Vue {
  @Prop({ default: -1 }) id!: number;
  @Prop({ default: -1 }) id_plantillas_cabezera!: number;

  public enums_estados: enumeracion[] = [];
  public enums_tipo: enumeracion[] = [];
  public expand: boolean = true;

  created() {
    if (this.is_new()) {
      plantilla_tareasModule.onGetplantilla_tareas(new tarea());
    } else {
      plantilla_tareasModule.getplantilla_tareas(this.id);
    }
    invetarioModule.getinvetarios();
    empresaModule.getempresas();
    usuarioModule.getusuarios();
    departamentoModule.getdepartamentos();
    proyectoModule.getproyectos();
    /*
    6	Estados de las tareas
    7	Tipo de tareas
    */

    enumeracionModule.getenumeraciones_fromtipo(6).then((x: enumeracion[]) => {
      this.enums_estados = x;
    });
    enumeracionModule.getenumeraciones_fromtipo(7).then((x: enumeracion[]) => {
      this.enums_tipo = x;
    });
  }

  get proyectos() {
    return proyectoModule.proyectos;
  }

  get datasource() {
    return plantilla_tareasModule.plantilla_tareas;
  }

  get usuarios() {
    return usuarioModule.usuarios;
  }

  get empresas() {
    return empresaModule.empresas;
  }
  get departamentos() {
    return departamentoModule.departamentos;
  }

  get inventarios() {
    return invetarioModule.invetarios.filter((x) => x.id_sub_inventario === 0);
  }

  public Save() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.datasource.id_cabezera_plantilla = this.id_plantillas_cabezera;
        if (this.is_new()) {
          plantilla_tareasModule
            .guardarplantilla_tareas(this.datasource)
            .then(() => this.finish_save());
        } else {
          plantilla_tareasModule
            .modificarplantilla_tareas(this.datasource)
            .then(() => this.finish_save());
        }
      }
    });
  }

  finish_save() {
    this.$emit("close");
  }

  public is_new() {
    return Number.parseInt(UtilsString.ValueOf(this.id)) === -1;
  }
}
